<template>
  <a-card style="width: 100%;">
    <div class="card-title mb20">
      <slot name="title">绑定用户</slot>
    </div>
    <div class="flex-start">
      <div style="width: 60%;margin-right: 15px;">
        <div class="flex-start margin-bottom">
          <img :src="data.headImage" style="width: 60px;height: 60px;margin-right: 15px;" alt="">
          <div>
            {{ data.name || '未知' }}<br>{{ sexAry[data.sex] }}<br>{{ data.phone || '暂无' }}
          </div>
        </div>
        <div class="flex-start margin-bottom">
          <span class="label">生日</span>
          <span class="text">{{ data.birthday || '未知' }}</span>
        </div>
        <div class="flex-start margin-bottom">
          <span class="label">兴趣</span>
          <span class="text">{{ getstr(data.userTabs) }}</span>
        </div>
        <div class="flex-start margin-bottom">
          <span class="label">数据</span>
          <span class="text">喜欢{{ data.like || 0 }} 收藏{{ data.favorite || 0 }}
              关注{{ data.follow || 0 }} </span>
        </div>
        <div class="flex-start">
          <span class="label">最后IP属地</span>
          <span class="text">{{ data.ipLocation || '未知' }}</span>
        </div>
      </div>
      <div style="flex:1;padding: 24px;" v-if="false">
        <div class="flex-start margin-bottom">
          <span class="label">状态</span>
          <span class="text">{{ statusAry[data.state] }}</span>
        </div>
        <div class="flex-start margin-bottom">
          <span class="label">实名认证</span>
          <span class="text">{{ realNameAry[data.real] }}</span>
        </div>
        <div class="flex-start margin-bottom">
          <span class="label">最后登录时间</span>
          <span class="text">{{ data.lastLoginTime }}</span>
        </div>
        <div class="flex-start">
          <span class="label">注册时间</span>
          <span class="text">{{ data.createdTime }}</span>
        </div>
      </div>
    </div>
  </a-card>
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
        default: () => {
          return {};
        }
      }
    },
    data() {
      return {
        gutter: 24,
        statusAry: ['禁用', '正常'],
        statusRealAry: ['未提交认证', '已认证', '真实性认证待审核'],
        realNameAry: ['未提交实名认证', '已实名认证', '实名认证待审核'],
        sexAry: ['女', '男']
      }
    },
    methods: {
      getstr(ary) {
        if (ary && ary.length > 0) {
          return ary.join('、');
        }
        return '暂无';
      }
    }
  }
</script>
<style lang="less" scoped>
  .common-detail-view {
    .header-info-main {
      padding: 20px;

      .C666 {
        display: inline-block;
        width: 90px;
        text-align: right;
      }
    }
  }

  .left-detail-card {
    width: 100%;
  }

  /deep/ .bindUser .ant-card-body {
    padding: 12px 0 6px 24px;
  }

  .user-info {
    margin-top: 20px;

    .user-info-content {
      padding: 20px;

      .user-info-certification {
        font-weight: bold;
        margin-bottom: 16px;
      }
    }
  }

  .ant-col {
    margin-bottom: 8px;
  }

  .ant-col:last-child {
    margin-bottom: 0;
  }

  .flex-start {
    display: flex;
    justify-content: flex-start;
  }

  .margin-bottom {
    margin-bottom: 10px;
  }

  .text {
    flex: 1;
  }

  // .ant-row{
  //   margin-bottom: 8px;
  // }
  .ant-row:last-child {
    margin-bottom: 0;
  }

  .label {
    width: 100px;
  }
</style>
