<!-- //
文章作品编辑
@auth xuyd
@date 2022-11-01
//-->
<template>
  <div class="layout-vertical">
    <a-form-model ref="refForm" :model="contentData" layout="vertical" class="layout-form p24">
      <a-form-model-item prop="uploadFileList" style="">
        <span slot="label" class="form-label">上传图片</span>
        <div class="img-preview">
          <div class="upload-img" @click="handlePreview(contentData.coverUri)">
            <img class="img" :src="ossUrl(contentData.coverUri)"/>
          </div>
        </div>
      </a-form-model-item>

      <a-form-model-item prop="title">
        <span slot="label" class="form-label">标题</span>
        <a-input v-model="contentData.title" class="form-value"
                 placeholder="请输入标题，简要突出内容重点"
                 :maxLength="titleMaxLength"
                 :suffix="titleWordLimit"
                 :disabled="true"
                 size="large"
        >
        </a-input>
      </a-form-model-item>

      <a-form-model-item prop="content">
        <span slot="label" class="form-label">正文</span>
        <Editor v-model="contentData.content" v-if="false" :disabled="true"></Editor>
        <div style="width: 100%; max-height:500px; overflow: auto; border: 1px solid #dddddd;padding: 10px;">
          <div v-html="contentData.content"></div>
        </div>
      </a-form-model-item>

      <a-form-model-item prop="tabList">
        <span slot="label" class="form-label">添加标签</span>
        {{ getStr(contentData.trendTab ||[]) }}
      </a-form-model-item>
    </a-form-model>

    <a-modal :visible="previewVisible" :footer="null" style="padding-top: 20px" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>

</template>

<script>

  import Editor from '@/components/Editor'
  import TagSelector from '@/components/TagSelector'

  export default {
    name: 'ArticleCompEdit',
    components: {
      Editor,
      TagSelector,
    },
    dictTypes: [
      {
        dictType: 'contentStatus',
        dataType: String
      },
      {
        dictType: 'contentType',
        dataType: Number
      },
      {
        dictType: 'contentEditCompType',
        dataType: Number
      },
      {
        dictType: 'contentListCompType',
        dataType: Number
      },
    ],
    data() {
      return {
        previewImage: null,
        previewVisible: null,
        form: {
          id: null,
          contentType: this.contentType || 1, //内容业务分类 1动态2视频3作品4文章
          title: null,  // 动态的标题
          trendStatus: 0, // 动态状态 0草稿 100未发布 200已发布
          content: null, // 动态内容
          coverUri: null, // 封面目录地址
          uploadFileList: [ // 上传组件文件列表 { uid: '-1', name: 'image.png', status: 'done', url: '',},
          ],
          fileList: [], // 接口返回的文件列表 视频文件 {uri,sort,type,appletComprImage，webComprImage, id(动态：内容id是1对多), flag :操作标记(1:新增 2:删除)
          trendTab: [], // 动态的兴趣标签 {tabId, tabName}
          productIds: [], // 动态的关联商品
        },
        deleteList: [], // 存储删除的文件，最终和uploadFileList一样merge到fileList发送给后端
        fileUploadLimit: 9, // 文件上传限制个数
        titleMaxLength: 30, // title最大len
      };
    },
    props: {
      contentData: {
        type: Object,
        default: () => {
          return {}
        }
      },
    },
    created() {
    },
    methods:{
      /**
       * 点击预览
       * @param file
       * @returns {Promise<void>}
       */
      handlePreview(filePath) {
        this.previewImage = null;
        let url = this.ossUrl(filePath)
        this.previewImage = url;
        this.previewVisible = true;
      },
      handleCancel() {
        this.previewVisible = false;
      },

      titleWordLimit() {
        let len = this.contentData.title ? this.contentData.title.length : 0;
        return len + '/' + this.titleMaxLength
      },
      ossUrl(uri) {
        console.log(uri)
        if (uri) {
          return this.$const.OssImagePrefix + '/' + uri;
        }
        return null;
      },

      getStr(ary) {
        if (ary && ary.length > 0) {
          return ary.join('、');
        }
        return '暂无';
      }
    },

  }
</script>

<style lang="less">

  .ant-form-vertical .ant-form-item {
    /*padding-bottom: 0;*/
  }

  .img-preview {
    .ant-upload.ant-upload-drag .ant-upload {
      padding: 0;
      margin: 0;
    }

    .ant-upload.ant-upload-drag {
      background-color: #ffffff;
    }
  }

</style>
<style lang="less" scoped>
  .form-label {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    text-align: left;
    line-height: 24px;

    .tip {
      font-size: 12px;
      font-weight: 300;
    }
  }

  .form-value {
    min-width: 375px;
    /*max-width: 700px;*/
  }

  .layout-form {
    max-width: 700px;

    .form-left {
      width: 375px;
    }

    .img-preview {
      width: 235px;
      height: 294px;
      padding: 0;
      margin: 0;
      display: inline-block;
      text-align: center;
      background: #ffffff;

      .upload-img {
        /*width: 100%;*/
        /*height: 100%;*/
        width: 235px;
        height: 294px;
        background-color: #404040;
        cursor: pointer;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .upload-empty {
        width: 100%;
        height: 100%;
        display: inline;
        vertical-align: middle;
      }

      .upload-text {
        font-size: 12px;
        font-weight: 400;
        color: #000000a5;
      }

      .upload-hint {
        font-size: 12px;
        font-weight: 400;
        color: #00000072;
      }
    }

    .form-right {
      flex: 1;
      margin-left: 48px;
    }
  }

  .modal-title {
    display: flex;

    .title {
      font-weight: 600;
      font-size: 14px;
    }

    .hint {
      margin-left: 10px;
      font-weight: 400;
      font-size: 12px;
      color: #0000006d;
    }
  }
</style>
