<template>
  <a-card style="width: 100%;">
    <div class="card-title mb20">
      <slot name="title">举报信息</slot>
    </div>
    <a-row>
      <a-row class="row">
        <a-col :span="4" class="label">举报类型</a-col>
        <a-col :span="20">
          {{ data.types }}
        </a-col>
      </a-row>
      <a-row class="row">
        <a-col :span="4" class="label">投诉时间</a-col>
        <a-col :span="20">
          {{$util.parseTime(data.complaintTime, '{y}-{m}-{d} {h}:{i}')}}
        </a-col>
      </a-row>
      <a-row class="row">
        <a-col :span="4" class="label">补充描述和凭证</a-col>
        <a-col :span="20">
          {{ data.complaintContent }}
        </a-col>
      </a-row>
      <a-row class="row">
        <a-col :span="4" class="label"></a-col>
        <a-col :span="20">
          <div class="img-list-h">
            <div class="img" v-for="(item, index) in complaintPics" :key="index">
              <img style="width: 100%;height: 100%" :src="ossUrl(item)" @click="handlePreview(item)"/>
            </div>
          </div>
        </a-col>
      </a-row>
    </a-row>

    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage"/>
    </a-modal>
  </a-card>
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
        default: () => {
          return {};
        }
      },
    },
    data() {
      return {
        previewImage: null,
        previewVisible: null,
      }
    },
    computed: {
      complaintPics() {
        let l = this.data.complaintPics;
        let retList = []
        if (l && l.length > 0) {
          l.forEach(item => {
            if (item) {
              let itemList = item.split(",")
              retList = retList.concat(itemList)
            }
          })
        }
        return retList;
      }
    },
    methods: {
      ossUrl(uri) {
        console.log(uri)
        if (uri) {
          return this.$const.OssImagePrefix + '/' + uri;
        }
        return null;
      },

      /**
       * 点击预览
       * @param file
       * @returns {Promise<void>}
       */
      handlePreview(filePath) {
        this.previewImage = null;
        let url = this.ossUrl(filePath)
        this.previewImage = url;
        this.previewVisible = true;
      },
      handleCancel() {
        this.previewVisible = false;
      },
    }
  }
</script>

<style lang="less" scoped>
  .row {
    margin-bottom: 8px;

    .label {
      width: 120px;
    }

    .img-list-h {
      display: flex;
      flex-direction: row;

      .img {
        width: 290px;
        height: 176px;
        margin-right: 20px;
      }
    }
  }
</style>
