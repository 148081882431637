<template>
  <div style="border: 1px solid #ccc;">
    <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        :defaultConfig="toolbarConfig"
        :mode="mode"
    />
    <Editor
        style="min-height: 400px; max-height: 1000px; overflow-y: hidden;"
        v-model="html"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onBlur="onBlur"
        @onCreated="onCreated"
    />
  </div>
</template>
<script>
  import Vue from 'vue'
  import '@wangeditor/editor/dist/css/style.css'
  import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
  import{DomEditor}from"@wangeditor/editor";
  import * as OssClient from "@/components/AliOss/OssClientEx.js";

  export default Vue.extend({
    name: 'RichEditor',
    components: {Editor, Toolbar},
    data() {
      return {
        editor: null,
        toolbar: null,
        html: this.value,
        toolbarConfig: {
          excludeKeys: [
            'fullScreen',
            'group-video',
            'undo',
            'redo',
            'fontFamily',
            'codeBlock',
            'emotion',
          ]
        },
        editorConfig: {
          placeholder: '请输入内容...',
          MENU_CONF: {
            uploadImage: {
              customUpload(file, insertFn) {
                let fileName = file.name
                OssClient.uploadFile({
                  bizType: 'image',
                  type: 'file',
                  fileName: fileName,
                  file: file
                }).then(result => {
                  let {name, url, path} = result
                  let alt = name
                  url = OssClient.ossUrl(path)
                  insertFn(url, alt, null)
                })
              }
            }
          },
        },
        mode: 'default', // or 'simple'
      }
    },
    model: {
      prop: 'value',
      event: 'blur',
    },
    props: {
      value: String,
    },
    watch: {
      value: {
        handler(val) {
          console.log('==========watch value======', val)
          this.html = val
        }
      },
      // html:{
      //   handler(val) {
      //     console.log('==========watch html======', val)
      //     this.$emit('blur', val)
      //   }
      // }
    },
    methods: {
      onCreated(editor) {
        this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        // this.toolbar = DomEditor.getToolbar(this.editor)
        // console.log("===>", this.editor.getAllMenuKeys())
        // console.log("==toolbar==>", toolbar.getConfig())
      },
      onBlur(editor) {
        // console.log('==========html======', this.html)
        this.$emit('blur', this.html)
      },
    },
    mounted() {
      // 模拟 ajax 请求，异步渲染编辑器
      // setTimeout(() => {
      //   this.html = '<p>模拟 Ajax 异步设置内容 HTML</p>'
      // }, 1500)
    },
    beforeDestroy() {
      const editor = this.editor
      if (editor == null) return
      editor.destroy() // 组件销毁时，及时销毁编辑器
    }
  })
</script>
