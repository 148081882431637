<!-- //
标签下拉选择器
@auth xuyd
@date 2022-09-10
//-->
<template>
  <div class="select-container">
    <a-select
      mode="multiple"
      placeholder="请选择或者添加标签"
      :value="selectedItems"
      style="width: 100%"
      size="large"
      @change="handleSelectedChange"
    >
      <div slot="dropdownRender">
        <a-tabs type="card" @mousedown="(e) => e.preventDefault()">
          <a-tab-pane
            v-for="(item, index) in list"
            :key="index"
            :tab="item.name"
          >
            <a-tag
              v-for="(subItem, subIndex) in item.children"
              :key="subIndex"
              color="#108ee9"
              @click="handleChecked(subItem)"
            >
              {{ subItem.name }}
            </a-tag>
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-select>
  </div>
</template>

<script>
const OPTIONS = [
  {
    tabId: 1,
    name: "家具家装",
    children: [
      { tabId: 11, name: "装修攻略" },
      { tabId: 12, name: "家具" },
      { tabId: 13, name: "生活家电" },
      { tabId: 14, name: "软装搭配" },
      { tabId: 15, name: "避坑指南" },
      { tabId: 16, name: "全屋案例" },
      { tabId: 17, name: "空间" },
      { tabId: 18, name: "风格" },
      { tabId: 19, name: "家具好屋" },
      { tabId: -1, name: "自定义", style: "CUSTOM" }
    ]
  },
  {
    tabId: 2,
    name: "设计",
    children: [
      { tabId: 22, name: "家具" },
      { tabId: 23, name: "生活家电" },
      { tabId: 24, name: "软装搭配" },
      { tabId: 25, name: "避坑指南" },
      { tabId: 26, name: "全屋案例" },
      { tabId: 27, name: "空间" },
      { tabId: 28, name: "风格" },
      { tabId: 29, name: "家具好屋" },
      { tabId: -1, name: "自定义", style: "CUSTOM" }
    ]
  },
  {
    tabId: 3,
    name: "露营",
    children: [
      { tabId: 31, name: "萌宠" },
      { tabId: 32, name: "家具" },
      { tabId: 33, name: "生活家电" },
      { tabId: 34, name: "软装搭配" },
      { tabId: 35, name: "避坑指南" },
      { tabId: 36, name: "全屋案例" },
      { tabId: 37, name: "空间" },
      { tabId: 38, name: "风格" },
      { tabId: 39, name: "家具好屋" },
      { tabId: -1, name: "自定义", style: "CUSTOM" }
    ]
  },
  {
    tabId: 4,
    name: "艺术",
    children: [
      { tabId: 41, name: "装修攻略" },
      { tabId: 42, name: "家具" },
      { tabId: 43, name: "生活家电" },
      { tabId: 44, name: "软装搭配" },
      { tabId: 45, name: "避坑指南" },
      { tabId: 46, name: "全屋案例" },
      { tabId: 47, name: "空间" },
      { tabId: 48, name: "风格" },
      { tabId: 49, name: "家具好屋" },
      { tabId: -1, name: "自定义", style: "CUSTOM" }
    ]
  },
  {
    tabId: 5,
    name: "潮玩",
    children: [
      { tabId: 51, name: "游戏" },
      { tabId: 52, name: "电竞" },
      { tabId: 53, name: "生活家电" },
      { tabId: 54, name: "软装搭配" },
      { tabId: 55, name: "避坑指南" },
      { tabId: 56, name: "全屋案例" },
      { tabId: 57, name: "空间" },
      { tabId: 58, name: "风格" },
      { tabId: 59, name: "家具好屋" },
      { tabId: -1, name: "自定义", style: "CUSTOM" }
    ]
  }
];
export default {
  components: {
    // VNodes: {
    //   functional: true,
    //   render: (h, ctx) => ctx.props.vnodes,
    // },
  },
  data() {
    return {
      selectedItems: this.value || [],
      list: OPTIONS
    };
  },
  props: {
    data: {
      type: Object
    },
    value: {
      type: Array,
      default: function () {
        return [];
      }
    },
    limit: Number
  },
  watch: {
    value() {
      this.selectedItems = this.value;
    }
  },
  computed: {
    filteredOptions() {
      return OPTIONS.filter((o) => !this.selectedItems.includes(o));
    }
  },
  methods: {
    handleSelectedChange(selectedItems) {
      this.selectedItems = selectedItems;
    },
    handleChecked(subItem) {
      this.selectedItems.push(subItem.name);
      this.$message.success(JSON.stringify(subItem));
    }
  }
};
</script>

<style lang="less" scoped></style>
