<template>
  <a-card style="width: 100%;">
    <div class="card-title mb20">
      <slot name="title">绑定用户</slot>
    </div>
    <a-row>
      <a-col :span="24" v-if="true">
        <a-row class="row">
          <a-col :span="4" style="width: 60px;height: 60px;margin-right: 15px;">
            <img class="logo" v-if="data.logo!==''" :src="`${$const.OssImagePrefix}/${data.logo}`"/>
            <img class="logo" v-else src="../../assets/common_emty.png"/>
          </a-col>
          <a-col :span="12">
            {{ data.shopName || '未知' }}
            <br/>
            联系方式：{{ data.phone || '暂无' }}
          </a-col>
        </a-row>
        <a-row class="row">
          <a-col :span="4" class="label">
            描述
          </a-col>
          <a-col :span="20">
            {{ data.describe || '暂无描述' }}
          </a-col>
        </a-row>
        <a-row class="row">
          <a-col :span="4" class="label">数据</a-col>
          <a-col :span="20">
            粉丝{{ data.fansNum || 0 }} 关注{{ data.follow || 0 }}
            获赞与收藏{{ data.favorite || 0 }}
          </a-col>
        </a-row>
        <a-row class="row">
          <a-col :span="4" class="label">最后IP属地</a-col>
          <a-col :span="20">
            {{ data.ipLocation || '暂无' }}
          </a-col>
        </a-row>
      </a-col>

      <a-col :span="10" v-if="false">
        <a-row class="row">
          <a-col :span="8">状态</a-col>
          <a-col :span="16">
            {{ statusAry[data.state] }}
          </a-col>
        </a-row>
        <a-row class="row">
          <a-col :span="8">{{ data.businessTag === 1 ? '个人认证' : '企业真实性认证' }}</a-col>
          <a-col :span="16">
            {{ statusRealAry[data.businessReal] }}
          </a-col>
        </a-row>
        <a-row class="row">
          <a-col :span="8">最后登录时间</a-col>
          <a-col :span="16">
            {{ data.lastLoginTime }}
          </a-col>
        </a-row>
        <a-row class="row">
          <a-col :span="8">注册时间</a-col>
          <a-col :span="16">
            {{ data.createdTime }}
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
        default: () => {
          return {};
        }
      },
    },
    data() {
      return {
        gutter: 24,
        statusAry: ['禁用', '正常'],
        statusRealAry: ['未提交认证', '已认证', '真实性认证待审核'],
        realNameAry: ['未提交实名认证', '已实名认证', '实名认证待审核'],
        sexAry: ['女', '男']
      }
    },
    methods: {
      getstr(ary) {
        if (ary && ary.length > 0) {
          return ary.join('、');
        }
        return '暂无';
      }
    }
  }
</script>
<style lang="less" scoped>
  .row {
    margin-bottom: 8px;

    .label {
      width: 100px;
    }
  }
</style>
