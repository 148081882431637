<template>
  <div class="layout-vertical" style="min-width: 1200px">
    <PageHeader :show-icon="true"
                :show-button="true"
                :show-confirm="false"
                :button-list="buttonList"
                @publishClick="handleSave"
                @saveClick="handleCancel">
    </PageHeader>
    <div class="layout-result p24">
      <DetailUser :data="personalResp">
        <template slot="title">
          <span>投诉人</span>
        </template>
      </DetailUser>
      <DetailShop class="mt24" :data="businessResp">
        <template slot="title">
          <span>被投诉店铺信息</span>
        </template>
      </DetailShop>
      <DetailComplaint class="mt24" :data="complaintInfoResp"></DetailComplaint>
      <DetailContent class="mt24">
        <template slot="title">
          <span>举报内容</span>
        </template>
        <template slot="content">
          <TrendPublish :detaildata="contentData" v-if="contentData.contentType == 1"/>
          <VideoPublish :detaildata="contentData" v-if="contentData.contentType == 2"/>
          <DetailArticle :contentData="contentData" v-if="contentData.contentType == 3 || contentData.contentType == 4"></DetailArticle>
        </template>
      </DetailContent>
      <div class="layout-form p24">
        <a-form-model ref="refForm" :model="form" :rules="rules" layout="vertical">
          <a-form-model-item label="投诉状态" prop="state">
            <a-radio-group v-model="form.state" style="width: 500px;">
              <a-radio v-for="(item, index) in dict.type.COMPLAINT_STATUS" :value="item.value" :key="index">
                {{item.label}}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="处理结果" prop="complaintResult">
            <a-input v-model="form.complaintResult" placeholder="请输入处理结果" :maxLength="200" style="width: 652px;"/>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>
</template>
<script>
  import * as ComplaintApi from '@/api/content/complaint.api'
  import DetailUser from '../../components/card-detail-user.vue'
  import DetailShop from '../../components/card-detail-shop.vue'
  import DetailComplaint from '../../components/card-detail-complaint.vue'
  import DetailContent from '../../components/card-detail-content.vue'
  import TrendPublish from '../common/trend-publish.vue'
  import VideoPublish from '../common/video-publish.vue'
  import DetailArticle from '../common/card-detail-article.vue'
  import * as Content from "@/api/content/video.api";

  export default {
    name: 'ComplaintDetail',
    components: {
      DetailUser,
      DetailShop,
      DetailComplaint,
      DetailContent,
      TrendPublish,
      VideoPublish,
      DetailArticle,
    },
    dictTypes: [
      {
        dictType: 'COMPLAINT_STATUS',
        dataType: Number
      },
    ],
    data() {
      return {
        buttonList: [
          {
            label: '保存',
            type: "primary"
          },
          {
            label: '取消',
            type: ""
          }
        ],
        contentType: null,
        personalResp: {},
        businessResp: {},
        complaintInfoResp: {},
        contentData: {},

        form: {
          id: null,
          state: null, // 审核状态 2已通过，3-未通过
          complaintResult: null,
        },
        rules: {
          state: [
            {
              required: true,
              message: '投诉状态必须选择',
              trigger: 'change'
            }
          ],
          complaintResult: [
            {
              required: true,
              message: '处理结果必须输入',
              trigger: 'blur'
            }
          ],
        }
      }
    },
    created() {
      this.form.id = this.$route.query.id;
      this.getDetail()
    },
    methods: {
      getDetail() {
        ComplaintApi.get(this.form.id).then(data => {
          console.log("========detail=======>", data)
          this.form.state = data.complaintInfoResp.state
          this.form.complaintResult = data.complaintInfoResp.complaintContent
          this.personalResp = data.personalResp
          this.businessResp = {
            ...data.businessInfoResp,
            phone: data.personalResp.phone
          }
          this.complaintInfoResp = data.complaintInfoResp || {};
          // 获取动态详情
          Content.detail(this.complaintInfoResp.trendId).then(res => {
            this.contentData = res.data || {}
            console.log("-----contentData----->", this.contentData)
          });
        })
      },
      handleSave() {
        this.$refs['refForm'].validate((valid) => {
          if (valid) {
            // console.log("================>", this.form)
            ComplaintApi.approve(this.form).then(data => {
              this.$modal.msg("保存成功")
              this.$router.replace({
                name: 'ComplaintList',
                query: {}
              })
            })
          } else {
            this.$modal.msgError('请先补全信息')
          }
        })
      },

      handleCancel() {
        this.$router.back()
      },
    }
  }
</script>
