/**
 * 新版ali oss的客户端，老版OssClient.js返回结构在各个页面使用，无法替换
 * 新作的使用OssClientEx.js版本（此版本和商户平台一致）
 */
import { parseTime, convertFileName } from "@/utils/util";
import store from "@/store";
var OSSConfig = store.state.user.OSSConfig;
store.dispatch("getOss").then((data) => {
  OSSConfig = data;
});
let OSS = require("ali-oss");
const path = require("path");

const dir = process.env.VUE_APP_BASE_DIR || "";
console.log("上传目录" + dir);

const ResponseState = {
  succ: function (data) {
    return { state: true, message: "", data: data };
  },
  err: function (message) {
    return { state: false, message: message, data: null };
  },
};

// 资源文件配置，单位M
const sizeUnit = {
  G: { size: 1073741824, name: "G" }, // 1024 * 1024 * 1024
  M: { size: 1048576, name: "M" }, // 1024 * 1024
  KB: { size: 1024, name: "KB" }, // 1024
  B: { size: 1, name: "B" }, // 1
};
// 资源文件配置，单位M
const OssBizConfig = {
  image: {
    path: "image/" + parseTime(new Date(), "{y}{m}") + "/" + dir,
    limitSize: 20971520,
  }, // 1024 * 1024 * 20
  video: {
    path: "video/" + parseTime(new Date(), "{y}{m}") + "/" + dir,
    limitSize: 1073741824,
  }, // 1024 * 1024 * 1024 byte = 1073741824
  gif: {
    path: "gif/" + parseTime(new Date(), "{y}{m}") + "/" + dir,
    limitSize: 20971520,
  },
  compress: {
    path: "compress/" + parseTime(new Date(), "{y}{m}") + "/" + dir,
    limitSize: 1073741824,
  },
};

const endpoint = "https://dongfang-yz-shanghai.oss-cn-shanghai.aliyuncs.com/";

/**
 * 地址直接拼接oss前缀
 *
 * @param filePath 文件的相对地址
 * @return {String} /oss/filePath
 */
export function ossUrl(filePath) {
  if (filePath) {
    if (filePath.length > 4 && filePath.substring(0, 4) === "http") {
      return filePath;
    }
    // return endpoint + filePath
    return process.env.VUE_APP_OSS_DIR + "/" + filePath;
  }
  return "";
}

/**
 * 地址直接拼接wechat前缀
 * 直接截取后留下的是/moment/就是请求前缀
 *
 * @param filePath 文件的相对地址
 * @return {String} /moment/filePathXXX
 */
export function wechatUrl(filePath) {
  if (filePath) {
    filePath = filePath.replace("https://thirdwx.qlogo.cn", "");
    return filePath;
  }
  return "";
}

export function convertImageUrl(filePath) {
  return wechatUrl(ossUrl(filePath));
}

/**
 * 富文本里面的oss地址截取
 *
 * @param filePath /oss/fileXXX
 * @returns {String} oss的全路径
 */
export function convertToOssUrl(filePath) {
  if (filePath) {
    return filePath.replaceAll("/oss/", endpoint);
  }
  return "";
}

/**
 * oss全路径截取域名后转成相对地址
 *
 * @param url oss的全路径 https://dongfang-yz-shanghai.oss-cn-shanghai.aliyuncs.com/fileXXX
 * @returns {String} /oss/fileXXX
 */
export function convertToOssPath(url) {
  if (url) {
    return url.replaceAll(endpoint, "/oss/");
  }
  return "";
}

/**
 * 配置跨域共享
 * @returns {Promise<void>}
 */
export async function configCors(client) {
  const rules = [
    {
      // 指定允许跨域请求的来源，支持通配符星号（*），表示允许所有的来源域。
      // allowedOrigin: 'http://example.com',
      allowedOrigin: "*",
      // 指定允许的跨域请求方法，支持GET、PUT、DELETE、POST和HEAD方法。
      allowedMethod: "*",
      // 指定允许跨域请求的响应头。建议无特殊情况下将此项设置为通配符星号（*）。
      allowedHeader: "*",
      // 指定允许用户从应用程序中访问的响应头，例如一个JavaScript的XMLHttpRequest对象。不允许使用通配符星号（*）。
      exposeHeader: "Content-Length",
      // 指定浏览器对特定资源的预取（OPTIONS）请求返回结果的缓存时间，单位为秒。
      maxAgeSeconds: "30",
    },
  ];
  try {
    const result = await client.putBucketCORS(OSSConfig.bucket, rules);
    console.log("配置跨域共享", result);
  } catch (err) {
    console.log(err);
  }
}

/**
 * 设置防盗链
 * @param client
 * @param refererList referer地址 eg:['example.com','*.example.com']
 * @returns {Promise<void>}
 */
export async function configReferer(client, refererList) {
  try {
    const result = await client.putBucketReferer(
      OSSConfig.bucket,
      true,
      refererList
    );
    console.log("设置防盗链", result);
  } catch (err) {
    console.log(err);
  }
}

/**
 * 查看存储空间列表
 * @returns {Promise<void>}
 */
export async function listBuckets(client) {
  try {
    const result = await client.listBuckets();
    console.log("查看存储空间列表", result);
  } catch (err) {
    console.log(err);
  }
}

/**
 * 查看文件列表
 * @returns {Promise<void>}
 */
export async function list(client) {
  // 不带任何参数，默认最多返回100个文件。
  let result = await client.list();
  console.log("查看文件列表", result);
}

/**
 * 上传文件
 * @type {{}}
 */
const headers = {
  // 指定该Object被下载时网页的缓存行为。
  "Cache-Control": "no-cache",
  // 指定该Object被下载时的名称。
  // 'Content-Disposition': 'oss_download.txt',
  // 指定该Object被下载时的内容编码格式。
  "Content-Encoding": "UTF-8",
  // 指定过期时间。
  // 'Expires': 'Wed, 08 Jul 2022 16:57:01 GMT',
  // 指定Object的存储类型。
  "x-oss-storage-class": "Standard",
  // 指定Object的访问权限。
  // 'x-oss-object-acl': 'private',
  // 设置Object的标签，可同时设置多个标签。
  // 'x-oss-tagging': 'Tag1=1&Tag2=2',
  // 指定CopyObject操作时是否覆盖同名目标Object。此处设置为true，表示禁止覆盖同名Object。
  // 'x-oss-forbid-overwrite': 'true',
};

const progress = (p, _checkpoint) => {
  // Object的上传进度。
  console.log("Object的上传进度", p);
  // 分片上传的断点信息。
  console.log("分片上传的断点信息", _checkpoint);
};

/**
 * 删除文件
 *
 * @param targetFilePath
 * @returns {Promise<unknown>}
 */
export function deleteFile(targetFilePath) {
  return new Promise((resolve, reject) => {
    try {
      let client = new OSS(OSSConfig);
      // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
      client
        .delete(targetFilePath)
        .then((result) => {
          console.log("删除文件", result);
        })
        .catch((err) => {
          console.log("删除文件", err);
          reject(err);
        });
    } catch (e) {
      console.log("删除文件", e);
      reject(e);
    }
  });
}

/**
 * 上传文件校验
 * @param params
 * @returns {{data: null, state: number, message: *}}
 */
export function validateUploadFile(params) {
  let { bizType, file } = params;
  let bizConfig = OssBizConfig[bizType];
  if (!bizConfig) {
    return ResponseState.err("未配置上传路径");
  }
  // 校验上传大小限制
  let fileSize = file.size;
  if (bizConfig.limitSize > 0) {
    // 单位换算
    let unit = {};
    if (bizConfig.limitSize >= sizeUnit.G.size) {
      unit = sizeUnit.G;
    } else if (bizConfig.limitSize >= sizeUnit.M.size) {
      unit = sizeUnit.M;
    } else if (bizConfig.limitSize >= sizeUnit.KB.size) {
      unit = sizeUnit.KB;
    } else {
      unit = sizeUnit.B;
    }
    let configName = unit.name;
    let configLimitSize = (bizConfig.limitSize / unit.size).toFixed(0);

    if (fileSize > bizConfig.limitSize) {
      let msg = `上传文件大小超过${configLimitSize}${configName}限制`;
      console.log(msg);
      return ResponseState.err(msg);
    }
  }
  return ResponseState.succ(true);
}

/**
 * 上传文件
 * @param params
 * @returns {Promise<unknown>}
 */
export async function uploadFile(params) {
  return new Promise((resolve, reject) => {
    let start = new Date();
    let data,
      { bizType, type, fileName, targetName, file } = params;
    let size = file.size;
    switch (type) {
      case "file":
        data = file;
        break;
      case "blob":
        // data = new Blob(file)
        data = file;
        break;
      case "buffer":
        data = new OSS.Buffer(file);
        break;
    }
    if (!data) {
      reject("未指定文件type:[file|blob|buffer]");
      return;
    }
    let bizConfig = OssBizConfig[bizType];
    if (!bizConfig) {
      reject("未配置上传路径");
      return;
    }

    try {
      let client = new OSS(OSSConfig);
      // 配置跨域
      // configCors(client)

      let targetFilePath = bizConfig.path + convertFileName(fileName);

      // debugger
      let a = 10;
      if (a == 1) {
        resolve({
          name: fileName,
          path: "202209/video/1664030711879.mp4",
          url: "http://dongfang-yz-shanghai.oss-cn-shanghai.aliyuncs.com/202209/video//1664030711879_test-video.mp4",
          res: {
            aborted: false,
            requestUrls: [
              "http://dongfang-yz-shanghai.oss-cn-shanghai.aliyuncs.com/202209/video//1664030711879_test-video.mp4",
            ],
          },
        });
        return;
      }

      // 填写OSS文件完整路径和本地文件的完整路径。OSS文件完整路径中不能包含Bucket名称。
      // 如果本地文件的完整路径中未指定本地路径，则默认从示例程序所属项目对应本地路径中上传文件。
      // 小于100M的默认上传，否则断点上传
      // if (size <= 104857600) { // 100M
      // if (size <= 1048576) { // 1M
      if (size <= 1073741824) {
        // 1G 强制单点上传
        client
          .put(targetFilePath, data, { progress: progress, timeout: -1 })
          .then((result) => {
            result.path = result.name;
            result.name = fileName;
            let end = new Date();
            let useTime = (end.getTime() - start.getTime()) / 1000 + "秒";
            console.log("OSS上传普通文件结果", result, "用时：" + useTime);
            resolve(result);
          });
      } else {
        // oss需要暴露 Headers：设置为ETag、x-oss-request-id和x-oss-version-id
        client
          .multipartUpload(targetFilePath, data, {
            progress: progress,
            timeout: -1,
          })
          .then((result) => {
            // , {headers}
            result.path = result.name;
            result.name = fileName;
            let end = new Date();
            let useTime = (end.getTime() - start.getTime()) / 1000 + "秒";
            console.log("OSS上传大文件结果", result, "用时：" + useTime);
            resolve(result);
          });
      }
    } catch (e) {
      console.log("OSS上传文件异常", e);
      reject(e);
    }
  });
}

/**
 * 上传url图片
 * @param targetFilePath 目标地址
 * @param firstFramePath 首帧url地址
 *
 */
export async function uploadFromUrl(targetFilePath, firstFramePath) {
  try {
    // 填写Object完整路径和本地文件的完整路径。Object完整路径中不能包含Bucket名称。
    // 如果指定的本地文件存在会覆盖，不存在则新建。
    // 如果未指定本地路径，则下载后的文件默认保存到示例程序所属项目对应本地路径中。
    let client = new OSS(OSSConfig);
    let result = await client.get(targetFilePath);
    console.log("OSS下载文件", result);
  } catch (e) {
    console.log(e);
  }
}

/**
 * 下载文件
 */
export async function get(targetFilePath) {
  try {
    // 填写Object完整路径和本地文件的完整路径。Object完整路径中不能包含Bucket名称。
    // 如果指定的本地文件存在会覆盖，不存在则新建。
    // 如果未指定本地路径，则下载后的文件默认保存到示例程序所属项目对应本地路径中。

    let client = new OSS(OSSConfig);
    let result = await client.get(targetFilePath);
    console.log("OSS下载文件", result);
  } catch (e) {
    console.log(e);
  }
}

/**
 * ajax 请求获取base64格式
 * @param {String}    url 需要请求的图片的连接
 * @return {Promise}  promise对象
 */
const getBase = (url) => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => response.blob()) // 将响应体转换成blob格式数据
      .then((blob) => {
        let reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      })
      .catch(console.error);
  });
};

/**
 * base64转化为对应的文件
 * @param {String}  base  base64字符
 * @param {String}  name  文件名称
 * @return: {file} 返回的文件
 */
const base64toFile = (base, filename) => {
  let arr = base.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  //转换成file对象
  return new File([u8arr], filename, {
    type: mime,
  });
};

/**
 * 图片转化为文件类型
 * @param {String} url  请求图片的地址
 * @param {String} name 转换后的文件名称
 * @return: {file}
 */
export async function imgUrlToFile(url, name) {
  let fileObj = {};
  await getBase(url).then((base) => {
    fileObj = base64toFile(base, name);
  });
  return fileObj;
}

export async function getImageFileFromUrl(url, imageName) {
  return new Promise((resolve, reject) => {
    var blob = null;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.setRequestHeader("Accept", "image/jpeg");
    xhr.responseType = "blob";
    xhr.onload = () => {
      blob = xhr.response;
      let imgFile = new File([blob], imageName, { type: "image/jpeg" });
      resolve(imgFile);
    };
    xhr.onerror = (e) => {
      reject(e);
    };
    xhr.send();
  });
}
