<!-- //
内容详情容器-视频/动态/文章/作品
@auth xuyd
@date 2022-11-20
//-->
<template>
  <a-card style="width: 100%;">
    <div class="card-title mb20">
      <slot name="title">内容信息</slot>
    </div>
    <a-row>
      <slot name="content"></slot>
    </a-row>
  </a-card>
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
        default: () => {
          return {};
        }
      },
    },
    data() {
      return {
        previewImage: null,
        previewVisible: null,
      }
    },
    computed: {
      complaintPics() {
        let l = this.data.complaintPics;
        let retList = []
        if (l && l.length > 0) {
          l.forEach(item => {
            if (item) {
              let itemList = item.split(",")
              retList = retList.concat(itemList)
            }
          })
        }
        return retList;
      }
    },
    methods: {
      ossUrl(uri) {
        console.log(uri)
        if (uri) {
          return this.$const.OssImagePrefix + '/' + uri;
        }
        return null;
      },

      /**
       * 点击预览
       * @param file
       * @returns {Promise<void>}
       */
      handlePreview(filePath) {
        this.previewImage = null;
        let url = this.ossUrl(filePath)
        this.previewImage = url;
        this.previewVisible = true;
      },
      handleCancel() {
        this.previewVisible = false;
      },
    }
  }
</script>

<style lang="less" scoped>
  .row {
    margin-bottom: 8px;

    .label {
      width: 120px;
    }

    .img-list-h {
      display: flex;
      flex-direction: row;

      .img {
        width: 290px;
        height: 176px;
        margin-right: 20px;
      }
    }
  }
</style>
